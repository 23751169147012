'use client';

import BackgroundVideo from '@/components/background-video';
import HeroVideo from '@/components/hero-video';
// import ArrowLeft from '@/components/icons/arrow-left';
import Reveal from '@/components/shared/animate/reveal';
// import Link from '@/components/shared/Link/link';
import Logo from '@/components/shared/logo';
// import { buttonVariants } from '@/components/ui/button';
import { Shell } from '@/components/ui/shell';
import { useTranslation } from '@/i18n/client';
import { Lang } from '@/i18n/settings';
import { animated, useInView } from '@react-spring/web';
import { Description, DescriptionProps } from './description';
import './index.css';

const buildInteractionObserverThreshold = (count = 100) => {
  const threshold = [];

  const parts = 1 / count;

  for (let i = 0; i <= count; i++) {
    threshold.push(parseFloat((parts * i).toFixed(2)));
  }

  return threshold;
};

type Props = {
  lang: Lang;
};

export function SectionOne({ lang }: Props) {
  const { t } = useTranslation(lang, 'section-one');

  const [imgref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 80,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: '-30% 0px -20% 0px',
      amount: buildInteractionObserverThreshold(),
    }
  );

  return (
    <div>
      <BackgroundVideo />
      <Shell as={'section'} className='overflow-hidden'>
        <div className='relative gap-8  xl:pt-0 flex flex-col '>
          <div
            ref={imgref}
            className='flex flex-col md:absolute left-0 top-0 right-0   bottom-[30%]   items-center justify-center gap-4'
          >
            <animated.div
              style={springs}
              className='items-center flex flex-col   justify-center gap-10'
            >
              <Logo
                containerClassName='max-w-[120px] md:max-w-[110px] lg:max-w-[160px] xl:max-w-[220px]'
                className='w-[180px]'
                width={500}
                height={500}
              />
              {/* <div className='flex flex-col items-center gap-4 justify-center'>
                <div className='flex flex-col items-center justify-center'>
                  <Link
                    href={'/voting'}
                    className={buttonVariants({
                      className: 'gap-4 ',
                      // variant: 'secondary',
                    })}
                  >
                    <span>صوت الان</span>
                    <ArrowLeft color='black' />
                  </Link>
                </div>
              </div> */}
            </animated.div>
          </div>
          <div className='flex flex-col -z-10 relative w-full'>
            <HeroVideo />
            <div className='md:absolute left-0 right-0 w-full flex items-start justify-start md:bottom-[0%] xl:bottom-[5%]'>
              <Reveal direction='right' className='w-full'>
                <Description
                  data={t('description', { returnObjects: true }) as DescriptionProps}
                  lang={lang}
                />
              </Reveal>
            </div>
          </div>
        </div>
      </Shell>
    </div>
  );
}
